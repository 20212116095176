/**
 * @module SlideXPagination
 * @author Kai Wieland <kai.wieland@werbewelt.de>
*/

/**
 * Creates a new SlideXPagination
 */
class SlideXPagination {

    /**
    * @private
     * @type {HTMLElement}
    */
    _moduleWrapper = null;

    /**
     * @private
     *@type {KeenSlider}
     */
     _slider = null;

    /**
     * @private
     * @type {HTMLElement}
     */
     _buttonsWrapper = null;

     /**
     * @private
     *@type {Array<ButtonElement>}
     */
    _buttons = [];

    /**
     * @param {HTMLElement} moduleWrapper
     */
    constructor(moduleWrapper){

        this._moduleWrapper = moduleWrapper;

        this._buttonsWrapper = document.createElement("div");
        this._buttonsWrapper.classList.add("slidex-pagination");
        this._moduleWrapper.appendChild(this._buttonsWrapper);
    }

    /**
     * @property {Function} init this function fires the init process of this class
     * @param {KeenSlider} slider
     * @returns {void}
     */
    init(slider){

        this._slider = slider;

        for (let i = 0; i < this._slider.slides.length; i++) {

            let tempButton = document.createElement("div");
            tempButton.classList.add("slidex-pagination__item");
            tempButton.setAttribute("data-slide-id", i + "");
            tempButton.addEventListener("click", (ev)=>{this._clickHandler(ev);});
            this._buttonsWrapper.appendChild(tempButton);
            this._buttons.push(tempButton);
        }

        this._slider.on("slideChanged", ()=>{this._updateClasses();});

        this._slider.on("created", ()=>{this._updateClasses();});

        this._slider.on("optionsChanged", ()=>{this._updateClasses();});

        this._updateClasses();
    }

    /**
     * @private
     * @property {Event} _clickHandler this function ist fired when the user clicks on a pagination button
     * @returns {void}
     */
     _clickHandler(event){

        let slideId = parseInt(event.target.getAttribute("data-slide-id"));
        this._slider.moveToIdx(slideId);
    }

    /**
     * @private
     * @property {Function} _updateClasses this function ist fired when the slider fires the created, slideChanged or optionsChanged event
     * @returns {void}
     */
    _updateClasses(){

        for (let i = 0; i < this._buttons.length; i++) {

            let button = this._buttons[i];

            let buttonId = parseInt(button.getAttribute("data-slide-id"));

            if(this._slider.track.details != null){

                let slide = this._slider.track.details.rel;

                if(buttonId == slide){

                    button.classList.add("active");

                }else{

                    button.classList.remove("active");
                }

            }else{

                if(buttonId == 0){

                    button.classList.add("active");

                }else{

                    button.classList.remove("active");
                }
            }
        }

        // if(Reflect.has(this._slider.options, "loop") == false || Reflect.get(this._slider.options, "loop") == false){

        //     if(this._slider.track.details != null){

        //         let slide = this._slider.track.details.rel;

        //         if(slide == 0){
        //             this._prevButton.classList.add("disabled");
        //         }else{
        //             this._prevButton.classList.remove("disabled");
        //         }

        //         if(slide === this._slider.track.details.slides.length - Math.floor(this._slider.options.slides.perView)){
        //             this._nextButton.classList.add("disabled");
        //         }else{
        //             this._nextButton.classList.remove("disabled");
        //         }

        //     }else{
        //         this._prevButton.classList.add("disabled");
        //     }
        // }
    }

}

export {SlideXPagination};