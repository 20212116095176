/**
 * @module SlideXEventType
 * @author Kai Wieland <kai.wieland@werbewelt.de>
*/

import { SlideXEvent } from "./SlideXEvent.js";

/**
 * Creates a new SlideXEventType
 */
class SlideXEventType {

    /**
     * @private
     * @type {string}
     */
    _eventName = "";

    /**
     * @private
     * @type {Array<Function>}
     */
    _callbks = [];

    /**
     * @private
     * @param {string} eventName
     */
    constructor(eventName){

        this._eventName = eventName;
    }

    /**
     * @property {Function} addCallback this function adds a callback function to the _callbks array
     * @param {Function} callbk
     * @returns {boolean}
     */
    addCallback(callbk){

        this._callbks.push(callbk);

        return true;
    }

    /**
     * @property {Function} removeCallback this function removes a callback function from to the _callbks array
     * @param {Function} callbk
     * @returns {boolean}
     */
    removeCallback(callbk){

        for (let i = 0; i < this._callbks.length; i++) {

            if(this._callbks[i] === callbk){

                this._callbks.splice(i, 1);
                return true;
            }
        }

        return false;
    }

    /**
     * @property {Function} dispatchCallbacks this function dispatches all callback functions from to the _callbks array
     * @returns {void}
     */
    dispatchCallbacks(){

        if(this._eventName == "created"){

            //console.log("created dispatchCallbacks");
            //console.log(this._callbks.length);
        }

        for (let i = 0; i < this._callbks.length; i++) {

            let tempEvent = new SlideXEvent(this._eventName, new Date());

            if(this._eventName == "created"){

                //console.log("************");
                //console.log(obj);
                //console.log(this._callbks[i]);
            }

            this._callbks[i](tempEvent);
            return true;
        }

        return false;
    }

    // GETTER SETTER

    /**
   * Get eventName
   * @returns {string} eventName
   */
    get eventName() {
        return this._eventName;
    }
}

export {SlideXEventType};