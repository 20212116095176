/**
 * @module SlideXTimer
 * @author Kai Wieland <kai.wieland@werbewelt.de>
*/

/**
 * Creates a new SlideXTimer
 */
class SlideXTimer {

    /**
     * @private
     * @type {Function}
     */
    _callbk = null;

    /**
     * @private
     * @type {number}
     */
    _timeInterval = null;

    /**
     * @private
     * @type {number}
     */
    _timeOld = null;

    /**
     * @private
     * @type {number}
     */
    _timeNow = null;

    /**
     * @private
     * @type {number}
     */
    _expected = null;

    /**
     * @private
     * @type {Function}
     */
    _timeout = null;

    /**
     * @param {Function} callbk Callback will be fired after timerinterval
     * @param {number} timeInterval Time interval in milliseconds
     */
     constructor(callbk, timeInterval){

        this._callbk = callbk;

        this._timeInterval = timeInterval;
    }

    /**
     * @property {Function} start starts the timer
     * @returns {void}
     */
    start(){

        if(this._timeout != null){

            clearTimeout(this._timeout);
        }

        this._expected = Date.now() + this._timeInterval;

        this._timeout = setTimeout(()=>{this._round();}, this._timeInterval);

        //console.log("Timer started");
    }

    /**
     * @property {Function} stop stops the timer
     * @returns {void}
     */
    stop(){

        clearTimeout(this._timeout);
        this._timeout = null;

        //console.log("Timer stopped");
    }

    /**
     * @private
     * @property {Function} _round Function will be looped each interval
     * @returns {void}
     */
     _round(){

        let drift = Date.now() - this._expected;

        this._callbk();

        this._expected += this._timeInterval;

        //console.log("-----");
        //console.log("drift", drift);
        //console.log("new time", this._timeInterval - drift);

        this._timeout = setTimeout(()=>{this._round();}, this._timeInterval - drift);
    }

}

export {SlideXTimer};