/**
 * @module SlideXNavigation
 * @author Kai Wieland <kai.wieland@werbewelt.de>
*/

/**
 * Creates a new SlideXNavigation
 */
class SlideXNavigation {

    /**
     * @private
     * @type {HTMLElement}
     */
    _moduleWrapper = null;

    /**
     * @private
     *@type {KeenSlider}
     */
     _slider = null;

     /**
     * @private
     *@type {HTMLElement}
     */
    _prevButton = null;

    /**
     * @private
     *@type {HTMLElement}
     */
    _nextButton = null;

    /**
     * @param {HTMLElement} moduleWrapper
     */
    constructor(moduleWrapper){

        this._moduleWrapper = moduleWrapper;

        this._prevButton = document.createElement("div");
        this._prevButton.classList.add("slidex-prev-button");
        this._moduleWrapper.appendChild(this._prevButton);

        this._nextButton = document.createElement("div");
        this._nextButton.classList.add("slidex-next-button");
        this._moduleWrapper.appendChild(this._nextButton);

    }

    /**
     * @property {Function} init this function fires the init process of this class
     * @param {KeenSlider} slider
     * @returns {void}
     */
    init(slider){

        this._slider = slider;

        this._prevButton.addEventListener("click", ()=>{this._prevHandler();});

        this._nextButton.addEventListener("click", ()=>{this._nextHandler();});

        this._slider.on("slideChanged", ()=>{this._updateClasses();});

        this._slider.on("created", ()=>{this._updateClasses();});

        this._slider.on("optionsChanged", ()=>{this._updateClasses();});

        this._updateClasses();
    }

    /**
     * @private
     * @property {Function} _prevHandler this function ist fired when the user clicks on the prev button
     * @returns {void}
     */
    _prevHandler(){

        this._slider.prev();
    }

    /**
     * @private
     * @property {Function} _prevHandler this function ist fired when the user clicks on the next button
     * @returns {void}
     */
    _nextHandler(){

        this._slider.next();
    }

    /**
     * @private
     * @property {Function} _updateClasses this function ist fired when the slider fires the created, slideChanged or optionsChanged event
     * @returns {void}
     */
    _updateClasses(){

        if(Reflect.has(this._slider.options, "loop") == false || Reflect.get(this._slider.options, "loop") == false){

            if(this._slider.track.details != null){

                let slide = this._slider.track.details.rel;

                if(slide == 0){
                    this._prevButton.classList.add("disabled");
                }else{
                    this._prevButton.classList.remove("disabled");
                }

                if(slide === this._slider.track.details.slides.length - Math.floor(this._slider.options.slides.perView)){
                    this._nextButton.classList.add("disabled");
                }else{
                    this._nextButton.classList.remove("disabled");
                }

            }else{
                this._prevButton.classList.add("disabled");
            }
        }
    }

    /**
     * @property {Function} next this function ist a public function to switch to the next slide
     * @returns {void}
     */
    next(){

        this._nextHandler();
    }

    /**
     * @property {Function} next this function ist a public function to switch to the previous slide
     * @returns {void}
     */
    prev(){

        this._prevHandler();
    }

    /**
     * @property {Function} next this function ist a public function to switch to slide with the id of slideId
     * @param {number} slideId
     * @returns {void}
     */
     slideto(slideId){

        this._slider.moveToIdx(slideId);
    }

}

export {SlideXNavigation};