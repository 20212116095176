/**
 * @module SlideXEventManager
 *
 * @author Kai Wieland <kai.wieland@werbewelt.de>
 * @description Lorem ipsum
*/

import { SlideXEventType } from "./SlideXEventType";
import KeenSlider from 'keen-slider';

/**
 * Creates a new SlideXEventManager
 */
class SlideXEventManager {

    /**
     * @private
     * @type {Array<SlideXEvent>}
     */
    _events = [];

    /**
     * List of all possible Event Types
     * @private
     * @type {Array<String>}
     */
     _eventFields = ["slideChanged", "created", "detailsChanged", "updated", "animationStarted", "animationEnded", "animationStopped", "enter", "out"];

    constructor(){

    }

    /**
     * @property {Function} registerEvents this function registers all neccessary event on the slider
     * @param {KeenSlider} slider
     * @returns {void}
     */
    registerEvents(slider){
        for (let i = 0; i < this._eventFields.length; i++) {

            this._events.push(new SlideXEventType(this._eventFields[i]));
        }

        slider.on("slideChanged", ()=>{this._dispatchEventHandler("slideChanged");});

        slider.on("detailsChanged", ()=>{this._dispatchEventHandler("detailsChanged");});

        slider.on("updated", ()=>{this._dispatchEventHandler("updated");});

        slider.on("animationStarted", ()=>{this._dispatchEventHandler("animationStarted");});

        slider.on("animationEnded", ()=>{this._dispatchEventHandler("animationEnded");});

        //console.log(this._events);
    }

    /**
     * @property {Function} _dispatchEventHandler this function calls the dispatch method for the given event type
     * @private
     * @param {string} eventName
     * @returns {void}
     */
    _dispatchEventHandler(eventName){

        if(eventName == "created"){

            //console.log(eventName);
        }

        let res = this.dispatch(eventName);

        if(eventName == "created"){

            //console.log(res);
        }
    }

    /**
     * @property {Function} addListener this function adds a callback function to the given event type
     * @param {string} eventName
     * @param {Function} callbk
     * @returns {boolean}
     */
    addListener(eventName, callbk){

        for (let i = 0; i < this._events.length; i++) {

            if(this._events[i].eventName === eventName){


                return this._events[i].addCallback(callbk);
            }
        }
        return false;
    }

    /**
     * @property {Function} addListener this function removes a callback function from the given event type
     * @param {string} eventName
     * @param {Function} callbk
     * @returns {boolean}
     */
     removeListener(eventName, callbk){

        for (let i = 0; i < this._events.length; i++) {

            if(this._events[i].eventName === eventName){

                return this._events[i].removeCallback(callbk);
            }
        }
        return false;
    }

    /**
     * @property {Function} dispatch this function dispatches the given event type
     * @param {string} eventName
     * @returns {boolean}
     */
    dispatch(eventName){

        if(eventName == "created"){
            //console.log(this._events);
        }

        for (let i = 0; i < this._events.length; i++) {

            if(this._events[i].eventName === eventName){

                if(eventName == "created"){
                    //console.log(this._events[i]);
                }
                return this._events[i].dispatchCallbacks();
            }
        }
        return false;
    }

    // GETTER SETTER

}

export {SlideXEventManager};