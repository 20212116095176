/**
 * @module SlideXCSS
 * @author Kai Wieland <kai.wieland@werbewelt.de>
*/

/**
 * Creates a new SlideXCSS
 */
class SlideXCSS {

    /**
    * @type {HTMLElement}
    */
    _moduleWrapper = null;

    /**
     * @type {HTMLImageElement}
     */
    _firstImage = null;

    /**
     * @type {HTMLElement}
     */
    _sliderElement = null;

    /**
     * @type {HTMLElement}
     */
    _firstSlide = null;

    /**
     * @type {HTMLImageElement}
     */
    _image = null;

    /**
     * @param {HTMLElement} moduleWrapper
     */
    constructor(moduleWrapper){

        this._moduleWrapper = moduleWrapper;

        this._sliderElement = this._moduleWrapper.querySelector(".keen-slider");

        this._firstSlide = this._sliderElement.querySelector(".slidex__slide");

        this._firstImage = this._firstSlide.querySelector("img");

        let tempSrc = this._firstImage.src;

        this._firstImage.src = null;

        this._firstImage.src = tempSrc;

        //console.log(this._firstImage.complete);

        if(this._firstImage.complete){

            //console.log("loaded");
            this._resizeHandler();
        }else{

            //console.log("not loaded");
            this._firstImage.addEventListener("load", ()=>{this._resizeHandler();});
        }

        window.addEventListener("resize", ()=>{this._resizeHandler();});

        setTimeout(()=>{this._resizeHandler();}, 250);
    }

    /**
     * @private
     * @property {Function} _resizeHandler  this function ist fired when the window gets resized
     * @returns {void}
     */
    _resizeHandler(){

        let newHeight = this._firstImage.naturalHeight * this._firstSlide.clientWidth / this._firstImage.naturalWidth;

        this._moduleWrapper.style.setProperty('--navigation-top', (newHeight / 2) + "px");
    }

    // GETTER SETTER

}

export {SlideXCSS};