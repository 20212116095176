/**
 * @module SlideXEvent
 * @author Kai Wieland <kai.wieland@werbewelt.de>
*/

/**
 * Creates a new SlideXEvent
 */
class SlideXEvent {

    /**
     * @private
     * @type {string}
     */
    _eventName = "";

    /**
     * @private
     * @type {number}
     */
    _time = null;

    /**
     * @private
     * @param {string} eventName
     */
    constructor(eventName, time){

        this._eventName = eventName;

        this._time = time;
    }

    // GETTER SETTER

    /**
     * Get eventName
     * @returns {string} eventName
     */
    get eventName() {
        return this._eventName;
    }

    /**
     * Get time
     * @returns {number} time
     */
     get time() {
        return this._time;
    }
}

export {SlideXEvent};